import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import React, { useState, useEffect, useRef } from 'react';

import Clickable from './Clickable';
import Hoverbox from './Hoverbox';
import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
    fontSize: props => {
      switch (props.size) {
        case 'small':
          return theme.font.size.small;
        default:
        case 'medium':
          return theme.font.size.text;
        case 'large':
          return theme.font.size.large;
      }
    }
  },
  dropDownItem: {
    backgroundColor: theme.color.background.default,
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.color.background.grey
    }
  },
  dropdownTarget: {
    '&:focus-visible': {
      outline: `2px auto ${theme.color.primary.dark}`,
      outlineOffset: '-1px'
    }
  },
  dropDownItemText: {
    fontFamily: theme.font.primary,
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    color: theme.color.text.main,
    letterSpacing: 0,
    textDecoration: 'none',
    fontSize: props => {
      switch (props.size) {
        case 'small':
          return theme.font.size.small;
        default:
        case 'medium':
          return theme.font.size.text;
        case 'large':
          return theme.font.size.large;
      }
    }
  },
  dropDownItemDisabled: {
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.color.background.default
    }
  },
  dropDownItemTextDisabled: {
    color: theme.color.text.light
  },
  dropDownContainer: {
    minWidth: ({ props, state }) => state.menuWidth,
    width: '100%'
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none !important'
      }
    }
  }
}));

export default function Dropdown(props) {
  const [visible, setVisible] = useState(false);
  const [menuWidth, setMenuWidth] = useState(0);
  const menuRef = useRef();
  const classes = useStyles({ props, state: { menuWidth } });

  function handleOpen() {
    setVisible(true);
    typeof props.onOpen === 'function' && props.onOpen();
  }

  function handleClose() {
    setVisible(false);
    typeof props.onClose === 'function' && props.onClose();
  }

  useEffect(() => {
    if (menuRef.current) {
      setMenuWidth(menuRef.current.offsetWidth);
    }
  }, [menuRef]);

  function handleEntryClick(entry, e) {
    e.stopPropagation();
    if (entry.onClick) entry.onClick();

    setVisible(false);
  }

  const entries = (
    <Box className={classes.entries}>
      {props.entries.map((entry, idx) => {
        return (
          <Clickable
            key={entry.title}
            href={entry.href}
            linkComponent={props.linkComponent}
            disabled={entry.disabled}
            onClick={e => handleEntryClick(entry, e)}
            onMouseEnter={e =>
              typeof entry.onMouseEnter === 'function' && entry.onMouseEnter(e)
            }
            onMouseLeave={e =>
              typeof entry.onMouseLeave === 'function' && entry.onMouseLeave(e)
            }
            className={classes.dropDownContainer}
          >
            <Box
              p={1}
              className={`${classes.dropDownItem} ${
                entry.disabled ? classes.dropDownItemDisabled : ''
              }`}
              display="flex"
              data-testid={entry['data-testid'] || `dropdown-item-${idx + 1}`}
            >
              {entry.icon && (
                <Icon
                  size={props.size}
                  name={entry.icon}
                  disabled={entry.disabled}
                />
              )}
              <Box pl={entry.icon ? 1 : 0}>
                <Box
                  className={`${classes.dropDownItemText} ${
                    entry.disabled ? classes.dropDownItemTextDisabled : ''
                  }`}
                >
                  {entry.title}
                </Box>
              </Box>
            </Box>
          </Clickable>
        );
      })}
    </Box>
  );

  return props.headless ? (
    entries
  ) : (
    <Hoverbox
      mode={props.mode}
      open={visible}
      id={props.id}
      disabled={props.disabled}
      onOpen={() => handleOpen()}
      onClose={() => handleClose()}
      borderless
      position={props.position}
      disablePortal
      target={
        <Box
          className={classes.dropdownTarget}
          ref={menuRef}
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === 'Enter' && !props.disabled) {
              if (visible) {
                handleClose();
              } else {
                handleOpen();
              }
            }
          }}
        >
          {props.children}
        </Box>
      }
    >
      {props.title ? (
        typeof props.title === 'string' || props.title instanceof String ? (
          <Box p={1} className={classes.title}>
            {props.title}
          </Box>
        ) : (
          props.title
        )
      ) : null}
      {entries}
    </Hoverbox>
  );
}

Dropdown.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  position: PropTypes.oneOf(['bottom', 'bottom right', 'bottom mouse']),
  linkComponent: PropTypes.any,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      href: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      title: PropTypes.string.isRequired,
      'data-testid': PropTypes.string
    })
  ).isRequired,
  disabled: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  headless: PropTypes.bool,
  children: (props, propName, componentName) => {
    if (!props[propName] && !props.headless) {
      return new Error(
        `The prop '${propName}' is marked as required in '${componentName}' in non headless mode.`
      );
    }
  },
  mode: PropTypes.oneOf(['hover', 'click'])
};

Dropdown.defaultProps = {
  id: 'dropdown',
  title: undefined,
  linkComponent: 'a',
  size: 'medium',
  disabled: false,
  headless: false,
  children: undefined,
  position: 'bottom',
  onOpen: undefined,
  onClose: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  mode: 'click'
};
