import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

import Icon from './Icon';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.color.text.main,
    hyphenateCharacter: 'auto',
    hyphens: 'auto',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    cursor: props => (props.onClick ? 'pointer' : null),
    '& h1': {
      // "Beschreibung und Ansprechpartner"
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.components?.heading?.h1?.fontSize || '1.25em',
      fontWeight: 700,
      lineHeight: '1.375em',
      margin: 0,
      marginBottom: props => (props.context ? 0 : '1.25em')
    },
    '& h2': {
      // main calendar heading
      fontSize: '1.125em',
      fontWeight: 600,
      lineHeight: '1.375em',
      margin: props => (props.context ? 0 : '1rem 0'),
      ...(theme.components?.heading?.h2 ? theme.components?.heading?.h2 : {})
    },
    '& h3': {
      // titles in booking-overview
      fontSize: '1em',
      fontWeight: 600,
      lineHeight: '1.375em',
      margin: 0,
      marginBottom: props => (props.context ? 0 : '.25em'),
      ...(theme.components?.heading?.h3 ? theme.components?.heading?.h3 : {})
    },
    '& h4': {
      // form labels
      fontSize: '0.875em',
      fontWeight: 600,
      lineHeight: '1.375em',
      margin: 0,
      ...(theme.components?.heading?.h4 ? theme.components?.heading?.h4 : {})
    },
    '& svg': {
      ...(theme.components?.heading?.icon
        ? theme.components?.heading?.icon
        : {})
    }
  },
  underline: {
    flex: 1,
    height: '0.0625em',
    background: theme.color.border.light
  }
}));

function getHeadingType(props) {
  const dataLabel = props['data-label'] || props.children;

  switch (props.type.toLowerCase()) {
    case 'h1':
      return (
        <h1
          data-label={dataLabel}
          data-testid={props['data-testid']}
          data-icon={props.icon}
        >
          {props.children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          data-label={dataLabel}
          data-testid={props['data-testid']}
          data-icon={props.icon}
        >
          {props.children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          data-label={dataLabel}
          data-testid={props['data-testid']}
          data-icon={props.icon}
        >
          {props.children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          data-label={dataLabel}
          data-testid={props['data-testid']}
          data-icon={props.icon}
        >
          {props.children}
        </h4>
      );
    default:
      return null;
  }
}

export default function Heading(props) {
  const classes = useStyles(props);

  let headingText = getHeadingType(props);
  let icon;

  if (props.icon) {
    icon = (
      <Icon
        size="large"
        name={props.icon}
        color="primary"
        lib={props.iconLib}
      />
    );
  }

  function getUnderline(underline) {
    return underline ? <div className={classes.underline} /> : null;
  }

  return (
    <Box className={props.className}>
      <Box
        display="flex"
        className={`${classes.margins} ${classes.heading} ${
          classes[props.type.toLowerCase()]
        }`}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        justifyContent={props.sideline || props.center ? 'center' : null}
        alignItems={props.sideline || props.center ? 'center' : null}
      >
        <Box display="flex" justifyContent="center">
          {props.icon && (
            <Box
              display="flex"
              mr="0.5em"
              alignItems="center"
              justifyContent="center"
            >
              {icon}
            </Box>
          )}
          <Box
            display="flex"
            style={props.sideline ? { marginRight: '0.5em' } : null}
          >
            {headingText}
          </Box>
        </Box>
        {props.sideline && <Box className={classes.underline} ml={2} />}
      </Box>
      {getUnderline(props.underline)}
    </Box>
  );
}

Heading.propTypes = {
  'data-label': PropTypes.string,
  'data-testid': PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  context: PropTypes.bool,
  icon: PropTypes.string,
  iconLib: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  sideline: PropTypes.bool,
  type: PropTypes.string.isRequired,
  underline: PropTypes.bool
};

Heading.defaultProps = {
  'data-label': undefined,
  'data-testid': undefined,
  center: false,
  children: [],
  className: '',
  context: false,
  icon: undefined,
  iconLib: undefined,
  onClick: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  sideline: false,
  underline: false
};
