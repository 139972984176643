// globalStrings.js
import { defineMessages } from 'react-intl';
import { convertToString } from '../date';

export function transformRequestStatus(status, intl) {
  switch (status) {
    case 'new':
      return `${intl.formatMessage({
        id: 'inbox',
        defaultMessage: 'Inbox'
      })} (${intl.formatMessage(jsonStrings['new'])})`;

    case 'completed':
      return intl.formatMessage(jsonStrings['decided']);

    default:
      return jsonStrings[status]
        ? intl.formatMessage(jsonStrings[status])
        : null;
  }
}

export function transformBookingStatus(status, intl) {
  switch (status) {
    case 'new':
      return intl.formatMessage(jsonStrings['unrated']);

    case 'in_progress':
      return intl.formatMessage(jsonStrings['in_evaluation']);

    case 'closure_in_progress':
      return intl.formatMessage(jsonStrings['in_progress']);

    case 'published':
      return intl.formatMessage(jsonStrings['completed']);

    default:
      return jsonStrings[status]
        ? intl.formatMessage(jsonStrings[status])
        : null;
  }
}

export function transformSeason(season) {
  return season
    ? `${season.name} | (${convertToString(new Date(season.start), {
        withDay: false,
        withTime: false,
        withYear: true
      })} - ${convertToString(new Date(season.end), {
        withDay: false,
        withTime: false,
        withYear: true
      })})`
    : null;
}

const jsonStrings = defineMessages({
  accepted: { defaultMessage: 'Accepted', id: 'accepted' },
  active: { defaultMessage: 'Active', id: 'active' },
  address: { defaultMessage: 'Address', id: 'address' },
  admin: { defaultMessage: 'Administrator', id: 'admin' },
  all: { defaultMessage: 'All', id: 'all' },
  attendees: { defaultMessage: 'Attendees', id: 'attendees' },
  association: { defaultMessage: 'Association', id: 'association' },
  bi_weekly: { defaultMessage: 'Bi weekly', id: 'bi_weekly' },
  blocked: { defaultMessage: 'Blocked', id: 'blocked' },
  booking_admin: { defaultMessage: 'Booking admin', id: 'booking_admin' },
  booking_applicant: {
    defaultMessage: 'Booking applicant',
    id: 'booking_applicant'
  },
  booking_trainer: { defaultMessage: 'Booking trainer', id: 'booking_trainer' },
  bookings: { defaultMessage: 'Bookings', id: 'bookings' },
  cancelled: { defaultMessage: 'Cancelled', id: 'cancelled' },
  church: { defaultMessage: 'Church', id: 'church' },
  city: { defaultMessage: 'City', id: 'city' },
  clerk: { defaultMessage: 'Clerk', id: 'clerk' },
  closure_permanent: {
    defaultMessage: 'Permanent',
    id: 'permanent'
  },
  closure_single: {
    defaultMessage: 'Single',
    id: 'single'
  },
  closure_type_care: {
    defaultMessage: 'Care',
    id: 'closure_type_care'
  },
  closure_type_maintenance: {
    defaultMessage: 'Maintenance',
    id: 'closure_type_maintenance'
  },
  closure_type_accident: {
    defaultMessage: 'Accident',
    id: 'closure_type_accident'
  },
  closure_type_already_occupied: {
    defaultMessage: 'Already occupied',
    id: 'closure_type_already_occupied'
  },
  closure_type_free_use: {
    defaultMessage: 'Free use',
    id: 'closure_type_free_use'
  },
  closure_type_no_operation_bridging_day: {
    defaultMessage: 'Bidging day',
    id: 'closure_type_no_operation_bridging_day'
  },
  closure_type_no_operation_holiday: {
    defaultMessage: 'Holiday',
    id: 'closure_type_no_operation_holiday'
  },
  closure_type_no_operation_other: {
    defaultMessage: 'Other',
    id: 'closure_type_no_operation_other'
  },
  closure_type_no_operation_vacation: {
    defaultMessage: 'Vacation',
    id: 'closure_type_no_operation_vacation'
  },
  closure_type_point_game_operation: {
    defaultMessage: 'Point game operation',
    id: 'closure_type_point_game_operation'
  },
  closure_type_reservation: {
    defaultMessage: 'Reservation',
    id: 'closure_type_reservation'
  },
  closure_type_miscellaneous: {
    defaultMessage: 'Miscellaneous',
    id: 'closure_type_miscellaneous'
  },
  closure_type_refurbishment: {
    defaultMessage: 'Refurbishment',
    id: 'closure_type_refurbishment'
  },
  closure_type_regeneration: {
    defaultMessage: 'Regeneration',
    id: 'closure_type_regeneration'
  },
  closure_type_renovation: {
    defaultMessage: 'Renovation',
    id: 'closure_type_renovation'
  },
  closure_type_ventilation: {
    defaultMessage: 'Ventilation',
    id: 'closure_type_ventilation'
  },
  closure_type_weather: {
    defaultMessage: 'Weather',
    id: 'closure_type_weather'
  },
  company: { defaultMessage: 'Company', id: 'company' },
  company_sports: { defaultMessage: 'Company sports', id: 'company_sports' },
  competition: { defaultMessage: 'Competition', id: 'competition' },
  completed: { defaultMessage: 'Completed', id: 'completed' },
  confirmed: { defaultMessage: 'Confirmed', id: 'confirmed' },
  consumer_type_association: {
    defaultMessage: 'association',
    id: 'consumer_type_association'
  },
  consumer_type_sports_club: {
    defaultMessage: 'sports_club',
    id: 'consumer_type_sports_club'
  },
  consumer_type_other: { defaultMessage: 'other', id: 'consumer_type_other' },
  consumer_type_school: {
    defaultMessage: 'school',
    id: 'consumer_type_school'
  },
  consumer_type_company: {
    defaultMessage: 'company',
    id: 'consumer_type_company'
  },
  consumer_type_person: {
    defaultMessage: 'person',
    id: 'consumer_type_person'
  },
  consumer_type_church: {
    defaultMessage: 'church',
    id: 'consumer_type_church'
  },
  consumer_type_city: { defaultMessage: 'city', id: 'consumer_type_city' },
  consumer_type_company_sports: {
    defaultMessage: 'company_sports',
    id: 'consumer_type_company_sports'
  },
  consumer_type_kindergarten: {
    defaultMessage: 'kindergarten',
    id: 'consumer_type_kindergarten'
  },
  consumer_type_municipal_organization: {
    defaultMessage: 'municipal_organization',
    id: 'consumer_type_municipal_organization'
  },
  consumer_type_university: {
    defaultMessage: 'university',
    id: 'consumer_type_university'
  },
  covered: { defaultMessage: 'Covered', id: 'covered' },
  daily: { defaultMessage: 'Daily', id: 'daily' },
  daily_mo_to_fr: { defaultMessage: 'Weekly till Fr.', id: 'daily_mo_to_fr' },
  daily_mo_to_sa: { defaultMessage: 'Weekly till Sa.', id: 'daily_mo_to_sa' },
  day_view: { defaultMessage: 'Day view', id: 'day_view' },
  declined: { defaultMessage: 'Declined', id: 'declined' },
  decided: { defaultMessage: 'Decided', id: 'decided' },
  default: { id: 'default', defaultMessage: 'Default' },
  draft: { defaultMessage: 'Draft', id: 'draft' },
  district: { defaultMessage: 'District', id: 'district' },
  division: { defaultMessage: 'Division', id: 'division' },
  division_manager: {
    defaultMessage: 'Division manager',
    id: 'division_manager'
  },
  domain_administering_institution_private: {
    defaultMessage: 'Private',
    id: 'domain_administering_institution_private'
  },
  domain_administering_institution_district: {
    defaultMessage: 'District',
    id: 'domain_administering_institution_district'
  },
  domain_administering_institution_sports_club: {
    defaultMessage: 'Sports club',
    id: 'domain_administering_institution_sports_club'
  },
  domain_administering_institution_state: {
    defaultMessage: 'State',
    id: 'domain_administering_institution_state'
  },
  domain_operating_institution_private: {
    defaultMessage: 'Private',
    id: 'domain_operating_institution_private'
  },
  domain_operating_institution_no_operator: {
    defaultMessage: 'no Operator',
    id: 'domain_operating_institution_no_operator'
  },
  domain_operating_institution_district: {
    defaultMessage: 'District',
    id: 'domain_operating_institution_district'
  },
  domain_operating_institution_sports_club: {
    defaultMessage: 'Sports club',
    id: 'domain_operating_institution_sports_club'
  },
  domain_operating_institution_state: {
    defaultMessage: 'State',
    id: 'domain_operating_institution_state'
  },
  domain_property_private: {
    defaultMessage: 'Private',
    id: 'domain_property_private'
  },
  domain_property_federal: {
    defaultMessage: 'Federal',
    id: 'domain_property_federal'
  },
  domain_property_sports_club: {
    defaultMessage: 'Sports club',
    id: 'domain_property_sports_club'
  },
  domain_property_state: {
    defaultMessage: 'State',
    id: 'domain_property_state'
  },
  email: { defaultMessage: 'E-Mail', id: 'email' },
  end: { defaultMessage: 'End', id: 'end' },
  exercise_leader: { defaultMessage: 'Exercise leader', id: 'exercise_leader' },
  facility_manager: {
    defaultMessage: 'Facility Manager',
    id: 'facility_manager'
  },
  false: { defaultMessage: 'False', id: 'false' },
  flooring: { defaultMessage: 'Flooring', id: 'flooring' },
  friday: { defaultMessage: 'Friday', id: 'friday' },
  general_manager: { defaultMessage: 'General manager', id: 'general_manager' },
  group_flooring_wood: { defaultMessage: 'wood', id: 'group_flooring_wood' },
  group_flooring_linoleum: {
    defaultMessage: 'Linoleum',
    id: 'group_flooring_linoleum'
  },
  group_flooring_parquet: {
    defaultMessage: 'Parquet',
    id: 'group_flooring_parquet'
  },
  group_flooring_plastic: {
    defaultMessage: 'Plastic',
    id: 'group_flooring_plastic'
  },
  group_flooring_other_coverings: {
    defaultMessage: 'Other coverings',
    id: 'group_flooring_other_coverings'
  },
  group_flooring_ice: { defaultMessage: 'Ice', id: 'group_flooring_ice' },
  group_flooring_without_information: {
    defaultMessage: 'Without information',
    id: 'group_flooring_without_information'
  },
  group_flooring_mix: { defaultMessage: 'Mix', id: 'group_flooring_mix' },
  group_flooring_concrete: {
    defaultMessage: 'Concrete',
    id: 'group_flooring_concrete'
  },
  group_flooring_mastic_asphalt: {
    defaultMessage: 'Mastic asphalt',
    id: 'group_flooring_mastic_asphalt'
  },
  group_flooring_synthetic_turf_granulate_filled: {
    defaultMessage: 'Synthetic turf granulate filled',
    id: 'group_flooring_synthetic_turf_granulate_filled'
  },
  group_flooring_synthetic_turf_quartz_sand_filled: {
    defaultMessage: 'Synthetic turf quartz sand filled',
    id: 'group_flooring_synthetic_turf_quartz_sand_filled'
  },
  group_flooring_synthetic_turf_unfilled: {
    defaultMessage: 'Synthetic turf unfilled',
    id: 'group_flooring_synthetic_turf_unfilled'
  },
  group_flooring_natural_grass: {
    defaultMessage: 'Natural grass',
    id: 'group_flooring_natural_grass'
  },
  group_flooring_sand: { defaultMessage: 'Sand', id: 'group_flooring_sand' },
  group_flooring_threshing_floor: {
    defaultMessage: 'Threshing floor',
    id: 'group_flooring_threshing_floor'
  },
  group_leader: { defaultMessage: 'Group leader', id: 'group_leader' },
  group_type: { defaultMessage: 'Group type', id: 'group_type' },
  group_type_gymnastics_meadow_lt_15m_20m: {
    id: 'group_type_gymnastics_meadow_lt_15m_20m',
    defaultMessage: 'Gymnastics meadow <15x20m'
  },
  group_type_hall_unknown_size: {
    id: 'group_type_hall_unknown_size',
    defaultMessage: 'Hall of unknown size'
  },
  group_type_multi_purpose_hall: {
    id: 'group_type_multi_purpose_hall',
    defaultMessage: 'Multi-purpose hall'
  },
  group_type_other_facility: {
    id: 'group_type_other_facility',
    defaultMessage: 'Other facility'
  },
  group_type_playground: {
    id: 'group_type_playground',
    defaultMessage: 'Playground'
  },
  group_type_playing_field_undetermined_size_class: {
    id: 'group_type_playing_field_undetermined_size_class',
    defaultMessage: 'Playing field of undetermined size class'
  },
  group_type_small_playing_field_gte_5000m2: {
    id: 'group_type_small_playing_field_gte_5000m2',
    defaultMessage: 'Small playing field >= 5000m²'
  },
  group_type_small_playing_field_lt_5000m2: {
    id: 'group_type_small_playing_field_lt_5000m2',
    defaultMessage: 'Small playing field < 5000m²'
  },
  group_type_sports_hall: {
    id: 'group_type_sports_hall',
    defaultMessage: 'Sports hall'
  },
  group_type_tennis_facility: {
    id: 'group_type_tennis_facility',
    defaultMessage: 'Tennis facility'
  },
  group_type_bowling_facility: {
    id: 'group_type_bowling_facility',
    defaultMessage: '(S) Bowling facility'
  },
  group_type_circular_track_400m: {
    id: 'group_type_circular_track_400m',
    defaultMessage: '400m circular track'
  },
  group_type_combination_bath: {
    id: 'group_type_combination_bath',
    defaultMessage: '(B) Combination bath'
  },
  group_type_equestrian_facility: {
    id: 'group_type_equestrian_facility',
    defaultMessage: '(S) Equestrian facility'
  },
  group_type_fitness_and_weight_room: {
    id: 'group_type_fitness_and_weight_room',
    defaultMessage: 'Fitness and weight room'
  },
  group_type_gymnastics_meadow: {
    id: 'group_type_gymnastics_meadow',
    defaultMessage: 'Gymnastics meadow'
  },
  group_type_hall_size_1_gte_10m_12m_lt_15m_27m: {
    id: 'group_type_hall_size_1_gte_10m_12m_lt_15m_27m',
    defaultMessage: 'Hall >=10x12m to <15x27m (size 1)'
  },
  group_type_hall_size_2_gte_15m_27m_lt_22m_44m: {
    id: 'group_type_hall_size_2_gte_15m_27m_lt_22m_44m',
    defaultMessage: 'Hall >=15x27m to <22x44m (size 2)'
  },
  group_type_hall_size_3_gte_22m_44m_lt_27m_45m: {
    id: 'group_type_hall_size_3_gte_22m_44m_lt_27m_45m',
    defaultMessage: 'Hall >=22x44m to <27x45m (size 3)'
  },
  group_type_hall_size_4_gte_27_45: {
    id: 'group_type_hall_size_4_gte_27_45',
    defaultMessage: 'Hall >=27x45m (size 4)'
  },
  group_type_ice_rink: {
    id: 'group_type_ice_rink',
    defaultMessage: '(S) Ice rink'
  },
  group_type_indoor_swimming_pool: {
    id: 'group_type_indoor_swimming_pool',
    defaultMessage: '(B) Indoor swimming pool'
  },
  group_type_laea_jumping: {
    id: 'group_type_laea_jumping',
    defaultMessage: 'LAEA (jumping)'
  },
  group_type_laea_other_circular_track: {
    id: 'group_type_laea_other_circular_track',
    defaultMessage: 'LAEA (other circular track)'
  },
  group_type_laea_shot_put: {
    id: 'group_type_laea_shot_put',
    defaultMessage: 'LAEA (shot put)'
  },
  group_type_laea_throw: {
    id: 'group_type_laea_throw',
    defaultMessage: 'LAEA (Throw)'
  },
  group_type_laea_track: {
    id: 'group_type_laea_track',
    defaultMessage: 'LAEA (track)'
  },
  group_type_large_playing_field: {
    id: 'group_type_large_playing_field',
    defaultMessage: 'Large playing field'
  },
  group_type_not_specified: {
    id: 'group_type_not_specified',
    defaultMessage: 'Not specified'
  },
  group_type_other_core_sports_facility: {
    id: 'group_type_other_core_sports_facility',
    defaultMessage: 'Other core sports facility'
  },
  group_type_outdoor_swimming_pool: {
    id: 'group_type_outdoor_swimming_pool',
    defaultMessage: '(B) Outdoor pool'
  },
  group_type_shooting_range: {
    id: 'group_type_shooting_range',
    defaultMessage: '(S) Shooting range'
  },
  group_type_small_hall_sports_room: {
    id: 'group_type_small_hall_sports_room',
    defaultMessage: 'Small hall, sports room'
  },
  group_type_small_playing_field: {
    id: 'group_type_small_playing_field',
    defaultMessage: 'Small playing field'
  },
  group_type_summer_pool: {
    id: 'group_type_summer_pool',
    defaultMessage: '(B) Summer pool'
  },
  group_type_teaching_swimming_and_therapy_pools: {
    id: 'group_type_teaching_swimming_and_therapy_pools',
    defaultMessage: '(B) Teaching swimming and therapy pools'
  },
  group_type_tennis_court: {
    id: 'group_type_tennis_court',
    defaultMessage: 'Tennis court'
  },
  group_type_tennis_hall: {
    id: 'group_type_tennis_hall',
    defaultMessage: 'Tennis hall'
  },
  group_type_water_sports_facility: {
    id: 'group_type_water_sports_facility',
    defaultMessage: '(S) Water sports facility'
  },
  group_type_youth_and_multi_purpose_room: {
    id: 'group_type_youth_and_multi_purpose_room',
    defaultMessage: 'Youth and multi-purpose room'
  },
  in_progress: { defaultMessage: 'In Progress', id: 'in_progress' },
  closure_in_progress: { defaultMessage: 'In Progress', id: 'in_progress' },
  in_evaluation: { defaultMessage: 'In Evaluation', id: 'in_evaluation' },
  indoor: { defaultMessage: 'Indoor', id: 'indoor' },
  indoor_slang: { defaultMessage: 'Indoor', id: 'indoor_slang' },
  kindergarten: { defaultMessage: 'Kindergarten', id: 'kindergarten' },
  large_event: { defaultMessage: 'Large event', id: 'large_event' },
  locked: { defaultMessage: 'Locked', id: 'locked' },
  manager: { defaultMessage: 'Manager', id: 'manager' },
  monday: { defaultMessage: 'Monday', id: 'monday' },
  month_view: { defaultMessage: 'Month view', id: 'month_view' },
  monthly: { defaultMessage: 'Monthly', id: 'monthly' },
  monthly_first: { defaultMessage: 'First of Month', id: 'monthly_first' },
  monthly_fourth: { defaultMessage: 'Fourth of Month', id: 'monthly_fourth' },
  monthly_second: { defaultMessage: 'Second of Month', id: 'monthly_second' },
  monthly_third: { defaultMessage: 'Third of Month', id: 'monthly_third' },
  multi_purpose_hall: {
    defaultMessage: 'Multi purpose hall',
    id: 'multi_purpose_hall'
  },
  municipal_organization: {
    defaultMessage: 'Municipal organization',
    id: 'municipal_organization'
  },
  name: { defaultMessage: 'Name', id: 'name' },
  new: { defaultMessage: 'New', id: 'new' },
  no: { defaultMessage: 'No', id: 'no' },
  not_covered: { defaultMessage: 'Not covered', id: 'not_covered' },
  occasion: { defaultMessage: 'Occasion', id: 'occasion' },
  occupancy_holiday: {
    defaultMessage: 'Holiday occupancy',
    id: 'occupancy_holiday'
  },
  occupancy_permanent: {
    defaultMessage: 'Permanent occupancy',
    id: 'occupancy_permanent'
  },
  occupancy_single: {
    defaultMessage: 'Single occupancy',
    id: 'occupancy_single'
  },
  organisation_type: {
    defaultMessage: 'Organisation type',
    id: 'organisation_type'
  },
  other: { defaultMessage: 'Other', id: 'other' },
  overall: { defaultMessage: 'Overall', id: 'overall' },
  owner: { defaultMessage: 'Owner', id: 'owner' },
  outdoor: { defaultMessage: 'Outdoor', id: 'outdoor' },
  outdoor_slang: { defaultMessage: 'Outdoor', id: 'outdoor_slang' },
  partially: { defaultMessage: 'Partially', id: 'partially' },
  person: { defaultMessage: 'Person', id: 'person' },
  phone: { defaultMessage: 'Phone', id: 'phone' },
  price: { defaultMessage: 'Price', id: 'price' },
  playground: { defaultMessage: 'Playground', id: 'playground' },
  processing: { defaultMessage: 'Processing', id: 'processing' },
  provider_admin: { defaultMessage: 'Administrator', id: 'provider_admin' },
  published: { defaultMessage: 'Published', id: 'published' },
  rejected: { defaultMessage: 'Rejected', id: 'rejected' },
  requests: { defaultMessage: 'Requests', id: 'requests' },
  saturday: { defaultMessage: 'Saturday', id: 'saturday' },
  school: { defaultMessage: 'School', id: 'school' },
  search: { defaultMessage: 'Search', id: 'search' },
  sports_club: { defaultMessage: 'Sports club', id: 'sports_club' },
  sports_hall: { defaultMessage: 'Sports hall', id: 'sports_hall' },
  start: { defaultMessage: 'Start', id: 'start' },
  statistic_01_bookings_by_type: {
    defaultMessage: '01: Total bookings by type',
    id: 'statistic_01_bookings_by_type'
  },
  statistic_02_closures_by_type: {
    defaultMessage: '02: Total closures by type',
    id: 'statistic_02_closures_by_type'
  },
  statistic_03_bookings_by_consumer_and_type: {
    defaultMessage: '03: Total bookings by consumer and type',
    id: 'statistic_03_bookings_by_consumer_and_type'
  },
  statistic_04_booking_requests_of_award_process: {
    defaultMessage: '04: Requests of award process',
    id: 'statistic_04_booking_requests_of_award_process'
  },
  statistic_05_bookings_of_award_process: {
    defaultMessage: '05: Bookings of award process',
    id: 'statistic_05_bookings_of_award_process'
  },
  statistic_06_closures_of_award_process: {
    defaultMessage: '06: Closures of award procress',
    id: 'statistic_06_closures_of_award_process'
  },
  statistic_07_bookings_and_free_capacity_by_group: {
    defaultMessage:
      '07: Occupancy (uses and closures) and free capacities per sports facility',
    id: 'statistic_07_bookings_and_free_capacity_by_group'
  },
  'statistic_09.1_domains': {
    defaultMessage: '09.1: Domains',
    id: 'statistic_09.1_domains'
  },
  'statistic_09.2_groups': {
    defaultMessage: '09.2: Groups',
    id: 'statistic_09.2_groups'
  },
  state: { defaultMessage: 'State', id: 'state' },
  status_draft: { defaultMessage: 'Status', id: 'status_draft' },
  street: { defaultMessage: 'Street', id: 'street' },
  sunday: { defaultMessage: 'Sunday', id: 'sunday' },
  system: { defaultMessage: 'System', id: 'system' },
  thursday: { defaultMessage: 'Thursday', id: 'thursday' },
  trainer: { defaultMessage: 'Trainer', id: 'trainer' },
  training: { defaultMessage: 'Training', id: 'training' },
  true: { defaultMessage: 'True', id: 'true' },
  tuesday: { defaultMessage: 'Tuesday', id: 'tuesday' },
  type: { defaultMessage: 'Type', id: 'type' },
  under_review: { defaultMessage: 'Under review', id: 'under_review' },
  undefined: { defaultMessage: 'No information', id: 'undefined' },
  university: { defaultMessage: 'University', id: 'university' },
  unrated: { defaultMessage: 'Unrated', id: 'unrated' },
  unlocked: { defaultMessage: 'Unlocked', id: 'unlocked' },
  unprocessed: { defaultMessage: 'Unprocessed', id: 'unprocessed' },
  wednesday: { defaultMessage: 'Wednesday', id: 'wednesday' },
  week_view: { defaultMessage: 'Week view', id: 'week_view' },
  weekly: { defaultMessage: 'Weekly', id: 'weekly' },
  withdrawn: { defaultMessage: 'Withdrawn', id: 'withdrawn' },
  yes: { defaultMessage: 'Yes', id: 'yes' }
});

export default jsonStrings;
