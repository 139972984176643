import {
  isAfter,
  isBefore,
  isDate,
  isTimeStamp,
  convertToString,
  isSameDayDate
} from '../date';
import { formatTime, isTime } from '../time';

const empty = value => isNaN(value) || value === '' || value === null;
const isNoValue = value => value === undefined || value === null;

export const compose =
  (validators = []) =>
  value => {
    if (!Array.isArray(validators))
      throw new Error('Compose only makes sense for an array of validators');

    return validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
  };

export const required = (value, options) => {
  const message = options?.customMessage
    ? options?.customMessage
    : 'Erforderlich, dieses Feld muss ausgefüllt werden';

  if (Array.isArray(value) && value.length === 0) {
    return message;
  }

  return value || value === 0 || value === false ? undefined : message;
};

export const number = value =>
  isNaN(value) ? 'Die Eingabe muss eine Zahl sein' : undefined;

export const minNumber = min => value =>
  isNaN(value) || value >= min
    ? undefined
    : `Die Eingabe darf ${min} nicht unterschreiten`;

export const maxNumber = max => value =>
  isNaN(value) || value <= max
    ? undefined
    : `Die Eingabe darf ${max} nicht überschreiten`;

export const minLength = min => value =>
  isNoValue(value) || value?.length >= min
    ? undefined
    : `Muss mindestens ${min} Zeichen lang sein`;

export const maxLength = max => value =>
  isNoValue(value) || value?.length <= max
    ? undefined
    : `Kann höchstens ${max} Zeichen lang sein`;

export const date = value =>
  isNoValue(value) || isDate(value) || isTimeStamp(value)
    ? undefined
    : 'Es muss ein Datum angegeben werden';

export const minDate = (min, message) => value =>
  empty(value) ||
  (isDate(value) && (isBefore(min, value) || isSameDayDate(min, value)))
    ? undefined
    : message
    ? message(min, value)
    : `Das Datum sollte nach ${convertToString(min, {
        withTime: false,
        withYear: true
      })} liegen.`;

export const maxDate = (max, message) => value =>
  empty(value) ||
  (isDate(value) && (isAfter(max, value) || isSameDayDate(max, value)))
    ? undefined
    : message
    ? message(max, value)
    : `Das Datum sollte vor ${convertToString(max, {
        withTime: false,
        withYear: true
      })} liegen.`;

export const time = value =>
  empty(value) || isTime(value) ? undefined : 'Ungültige Uhrzeit';

export const minTime = min => value =>
  empty(value) || formatTime(min)?.number <= formatTime(value)?.number
    ? undefined
    : `Es muss eine Uhrzeit nach ${min} Uhr angegeben werden`;

export const maxTime = max => value =>
  empty(value) || formatTime(max)?.number >= formatTime(value)?.number
    ? undefined
    : `Es muss eine Uhrzeit vor ${max} Uhr angegeben werden`;

export const timeRange = value => {
  if (value?.start && value?.end) {
    const start = formatTime(value.start)?.number;
    const end = formatTime(value.end)?.number;

    return start >= end ? 'Ungültiger Zeitraum' : undefined;
  }

  return undefined;
};

export const minElements = min => values =>
  values === undefined || values === [] || min <= values.length
    ? undefined
    : `Es ${min > 1 ? 'müssen' : 'muss'} mindestens ${min} Element${
        min > 1 ? 'e' : ''
      } ausgewählt werden`;

export const maxElements = max => values =>
  values === undefined || values === [] || max >= values?.length
    ? undefined
    : `Es dürfen maximal ${max} Element${
        max.length > 1 ? 'e' : ''
      } ausgewählt werden`;

export const isLink = value => {
  if (!value) {
    return undefined;
  }

  try {
    if (!value.startsWith('http://') && !value.startsWith('https://')) {
      return 'Kein gültiger Link (Muss mit "http://" oder "https://" beginnen)';
    }

    new URL(value);

    return undefined;
  } catch (_err) {
    return 'Kein gültiger Link';
  }
};

export const isEmail = value => {
  if (!value) {
    return undefined;
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value) ? undefined : 'Keine gültige E-Mail';
};

export const isPhoneNumber = value => {
  if (!value) {
    return undefined;
  }

  const re =
    /^[+]{0,1}[0-9]{1,2}[\ ]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$/;
  return re.test(value) ? undefined : 'Keine gültige Eingabe';
};
