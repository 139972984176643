import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Autocomplete as MuiAutocomplete, Popper } from '@mui/material';
import { Field, useField } from 'react-final-form';
import { TextField as MuiTextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from './Button';
import Label from './Label';
import Void from './Void';
import Spacing from './Spacing';
import { compose, minElements, required } from '../utils/form/validators';

const useStyles = makeStyles(theme => ({
  typeahead: {
    width: props => (props.fullWidth ? '100%' : '15em')
  },
  muiAutocomplete: {
    display: 'flex',
    alignItems: 'center',
    width: props => (props.fullWidth ? '100%' : '15em'),
    '& .MuiOutlinedInput-root': {
      fontSize: theme.font?.size?.input,
      paddingBottom: '.625em',
      paddingLeft: '.75em',
      paddingTop: '.625em'
    },
    ...(theme.components?.typeahead?.root
      ? theme.components?.typeahead?.root
      : {})
  },
  muiTextField: {
    '& .MuiOutlinedInput-input': {
      padding: '0 !important',
      '&::placeholder': {
        color: theme.color.text.light,
        opacity: 1
      }
    },
    '&  .Mui-disabled.MuiOutlinedInput-input': {
      cursor: 'not-allowed'
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.error.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.border.main
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.primary.main
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.border.light
    },
    '& .Mui-disabled.MuiOutlinedInput-input': {
      textFillColor: theme.color.text.light
    },
    '& .MuiFormHelperText-root': {
      margin: 0
    },
    ...(theme.components?.textField?.root
      ? theme.components?.textField?.root
      : {})
  },
  muiSelectMenu: {
    border: `1px solid ${theme.color.border.main}`,
    borderTop: 'transparent',
    borderRadius: '0 0 4px 4px',
    '&:hover': {
      borderColor: theme.color.border.dark
    },
    '& li': {
      padding: '.5em .5em',
      minHeight: 'auto',
      lineHeight: '1.1em',
      fontSize: theme.font.size.input,
      '&[aria-disabled="true"]': {
        color: theme.color.text.light,
        cursor: 'default',
        opacity: '1 !important',
        pointerEvents: 'all !important',
        '&:hover': {
          backgroundColor: 'initial'
        }
      },
      '&[aria-selected="true"]': {
        background: `${theme.color.background.grey} !important`,
        '&:hover': {
          background: theme.color.background.grey
        }
      }
    },
    ...(theme.components?.typeahead?.selectMenu
      ? theme.components?.typeahead?.selectMenu
      : {})
  },
  unsearchbale: {
    caretColor: 'transparent',
    '& *': {
      cursor: 'default'
    }
  },
  loadMore: {
    padding: '0 !important',
    ...(theme.components?.typeahead?.loadMore
      ? theme.components?.typeahead?.loadMore
      : {})
  }
}));

const Typeahead = React.forwardRef(function Typeahead(props, ref) {
  const classes = useStyles(props);
  const field = useField(props.id);
  const [loading, setLoading] = useState(props.loading);
  const [textFieldValue, setTextFieldValue] = useState(
    props.value || props.defaultValue
      ? props.getOptionLabel(props.value || props.defaultValue)
      : ''
  );
  const delayTimeout = useRef();
  const [pagination, setPagination] = useState({
    page: props.defaultPage,
    size: props.defaultPageSize
  });

  async function handlePageChange(e) {
    e.preventDefault();
    e.stopPropagation();

    const updatedPagination = { ...pagination, page: pagination.page + 1 };
    setPagination(updatedPagination);

    setLoading(true);
    await props.onInputChange(
      textFieldValue,
      e,
      props.pagination
        ? {
            pagination: updatedPagination
          }
        : {}
    );
    setLoading(false);
  }

  async function handleInputChange(e, newValue) {
    if (!props.disabled && newValue !== props.intl?.loadMore) {
      const updatedPagination = {
        page: props.defaultPage,
        size: props.defaultPageSize
      };
      setPagination(updatedPagination);
      setTextFieldValue(newValue);

      if (props.onInputChange) {
        clearTimeout(delayTimeout.current);

        setLoading(true);

        delayTimeout.current = setTimeout(async () => {
          await props.onInputChange(
            newValue,
            e,
            props.pagination
              ? {
                  pagination: updatedPagination
                }
              : {}
          );
          setLoading(false);
        }, 100);
      }
    } else if (newValue === props.intl?.loadMore) {
      handlePageChange(e);
    }
  }

  function handleChange(v, e) {
    field.input.onChange(v === null ? undefined : v);

    if (props.onChange) props.onChange(v, e);
  }

  function handleBlur() {
    field.input.onBlur();

    if (props.onBlur) props.onBlur();
  }

  function handleFocus(e) {
    field.input.onFocus();
    handleInputChange(e, textFieldValue);

    if (props.onFocus) props.onFocus();
  }

  function getValidators() {
    const validators = [];

    if (props.required) {
      validators.push(required);

      if (props.multiselect) {
        validators.push(minElements(1));
      }
    }

    return validators;
  }

  function removeValue(value) {
    if (field.input?.value?.length) {
      const updatedValues = [];

      field.input.value.forEach(v => {
        if (!props.isOptionEqualToValue(value, v)) {
          updatedValues.push(v);
        }
      });

      field.input.onChange(updatedValues);
    }
  }

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  return (
    <Field name={props.id} validate={compose(getValidators())}>
      {({ meta, input }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');

        useEffect(() => {
          if (input.value) {
            if (props.multiselect) {
              setTextFieldValue('');
            } else {
              setTextFieldValue(props.getOptionLabel(input.value));
            }
          } else {
            setTextFieldValue('');
          }
        }, [input.value]);

        function getSaveValue() {
          if (input.value === '') {
            if (props.multiselect) {
              return [];
            }

            return null;
          }

          return input.value;
        }

        const options = [...props.options];

        if (
          props.pagination &&
          props.options.length &&
          pagination.page * pagination.size <= props.options.length
        ) {
          // Append 'Load More' button to the result list.
          options.push({
            loadMoreButton: true,
            handlePageChange: handlePageChange,
            caption: props.intl?.loadMore
          });
        }

        return (
          <div className={classes.typeahead}>
            <Label
              for={props.id}
              disabled={props.disabled}
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
            >
              {props.label}
            </Label>
            <MuiAutocomplete
              id={props.id}
              autoHighlight
              autoComplete={props.searchable}
              className={classes.muiAutocomplete}
              options={options}
              clearOnEscape
              disablePortal
              defaultValue={props.defaultValue}
              fullWidth
              disableCloseOnSelect={props.multiselect}
              classes={{
                paper: classes.muiSelectMenu
              }}
              filterOptions={
                props.onInputChange ? (options, state) => options : undefined
              }
              clearText={props.intl?.clear}
              closeText={props.intl?.close}
              getOptionDisabled={props.isOptionDisabled}
              loading={loading}
              disabled={props.disabled && !meta.active}
              multiple={props.multiselect}
              value={getSaveValue()}
              onChange={(e, newValue, reason) => {
                if (reason === 'clear') {
                  handleChange(newValue, e);
                  // Make sure this runs after focus event
                  setTimeout(() => {
                    handleInputChange(e, '');
                  });
                } else {
                  if (!newValue?.loadMoreButton) {
                    handleChange(newValue, e);
                  }
                }
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              inputValue={textFieldValue}
              onInputChange={props.searchable ? handleInputChange : null}
              openText={props.intl?.open}
              loadingText={props.intl?.loading}
              popupIcon={
                loading ? (
                  <CircularProgress
                    aria-label={props.intl?.loading}
                    data-testid="typeahead-loading"
                    color="inherit"
                    size={20}
                  />
                ) : undefined
              }
              noOptionsText={
                !textFieldValue?.length
                  ? props.intl?.typeSomething
                  : props.intl?.noResult
              }
              renderOption={(renderOptionProps, option, state) => {
                if (option.loadMoreButton) {
                  // We have to remove listeners otherwise click on loading
                  // leads to scroll to top
                  delete renderOptionProps.onClick;
                  delete renderOptionProps.onTouchStart;
                  delete renderOptionProps.onMouseOver;

                  return (
                    <li
                      {...renderOptionProps}
                      className={`${renderOptionProps.className} ${classes.loadMore}`}
                      onMouseOver={e => {
                        // Remove focused style/class from list item
                        const focusedElements = document
                          .getElementById(`${props.id}-items`)
                          ?.getElementsByClassName('Mui-focused');

                        if (focusedElements.length) {
                          focusedElements[0].classList.remove('Mui-focused');
                        }
                      }}
                    >
                      <Button
                        fullWidth
                        color="primary"
                        onClick={option.handlePageChange}
                      >
                        {option.caption}
                      </Button>
                    </li>
                  );
                } else {
                  return React.isValidElement(option) ? (
                    <li
                      {...renderOptionProps}
                      onClick={e =>
                        renderOptionProps['aria-disabled']
                          ? e.preventDefault()
                          : renderOptionProps.onClick(e)
                      }
                      title={
                        renderOptionProps['aria-disabled']
                          ? props.disabledOptionTitle
                          : null
                      }
                    >
                      {option}
                    </li>
                  ) : (
                    <li
                      {...renderOptionProps}
                      onClick={e =>
                        renderOptionProps['aria-disabled']
                          ? e.preventDefault()
                          : renderOptionProps.onClick(e)
                      }
                      title={
                        renderOptionProps['aria-disabled']
                          ? props.disabledOptionTitle
                          : null
                      }
                    >
                      {props.getOptionLabel(option)}
                    </li>
                  );
                }
              }}
              ListboxProps={{
                id: `${props.id}-items`,
                ['data-testid']: `${props.id}-items`
              }}
              getOptionLabel={option => {
                if (option.loadMoreButton) {
                  return option.caption || '';
                } else {
                  return props.getOptionLabel(option) || '';
                }
              }}
              isOptionEqualToValue={props.isOptionEqualToValue}
              limitTags={props.multiselect ? 0 : -1}
              getLimitTagsText={() => <div />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return null;
                })
              }
              renderInput={params => (
                <>
                  <MuiTextField
                    {...params}
                    className={
                      props.searchable
                        ? classes.muiTextField
                        : `${classes.muiTextField} ${classes.unsearchbale}`
                    }
                    // TBD: Intl
                    placeholder={props.placeholder || 'Bitte wählen'}
                    error={error}
                    disabled={props.disabled}
                    helperText={errorText || props.helperText}
                    FormHelperTextProps={{
                      'aria-label': errorText || props.helperText
                    }}
                    InputProps={{
                      ...params.InputProps,
                      notched: false
                    }}
                    inputRef={ref}
                    autoFocus={props.autoFocus}
                    variant="outlined"
                  />
                  {props.createButtonHref ? (
                    <>
                      <Spacing x={1} />
                      <div
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <Box position="relative">
                          <a
                            aria-label={props.createButtonAriaLabel}
                            href={
                              !props.disabled ? props.createButtonHref : null
                            }
                          >
                            <Button
                              variant="contained"
                              aria-label={props.createButtonAriaLabel}
                              icon
                              size="small"
                              color="primary"
                              disabled={props.disabled}
                            >
                              add
                            </Button>
                          </a>
                        </Box>
                      </div>
                    </>
                  ) : (
                    <Void />
                  )}
                </>
              )}
            />
            {props.multiselect && input.value.length ? (
              <Box mt={1} display="flex" flexWrap="wrap">
                {input.value.map(v => {
                  const label = props.getOptionLabel(v);

                  return (
                    <Box mr={0.5} mb={0.5} key={label}>
                      <Button
                        disabled={props.disabled}
                        onClick={() => removeValue(v)}
                        size="small"
                        variant="outlined"
                        endIcon="close"
                      >
                        {label}
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </div>
        );
      }}
    </Field>
  );
});

Typeahead.propTypes = {
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.object,
  disabled: PropTypes.bool,
  disabledOptionTitle: PropTypes.string,
  endIcon: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.node,
  helperText: PropTypes.node,
  multiselect: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  intl: PropTypes.shape({
    clear: PropTypes.string,
    close: PropTypes.string,
    loading: PropTypes.string,
    loadMore: PropTypes.string,
    open: PropTypes.string,
    noResult: PropTypes.string,
    typeSomething: PropTypes.string
  }),
  getOptionLabel: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func.isRequired,
  isOptionDisabled: PropTypes.func,
  searchable: PropTypes.bool,
  pagination: PropTypes.bool,
  defaultPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  label: PropTypes.node,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  value: PropTypes.object,
  inputValue: PropTypes.string
};

Typeahead.defaultProps = {
  autoFocus: false,
  defaultValue: undefined,
  disabled: false,
  disabledOptionTitle: undefined,
  endIcon: undefined,
  error: false,
  multiselect: false,
  errorText: undefined,
  fullWidth: false,
  loading: false,
  options: [],
  intl: {
    open: 'Öffnen',
    clear: 'Zurücksetzen',
    close: 'Schließen',
    loading: 'Lade…',
    noResult: 'Kein Ergebnis',
    typeSomething: 'Starten Sie die Eingabe…',
    loadMore: 'Mehr laden'
  },
  getOptionLabel: () => {},
  isOptionEqualToValue: () => {},
  isOptionDisabled: () => false,
  searchable: true,
  pagination: false,
  helperText: undefined,
  defaultPage: 1,
  defaultPageSize: 10,
  label: undefined,
  onChange: () => {},
  onInputChange: undefined,
  placeholder: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  value: undefined,
  inputValue: undefined
};

export default Typeahead;
